import React from "react"
import Layout from "../components/Layout"

const PrivacyPolicy = () => {
  return (
    <Layout>
      <div className="public_page">
        <h4>
          <strong>PRIVACY POLICY</strong>
        </h4>
        <p>
          Your privacy is important to us. This privacy policy governs the
          manner in which AlignerBase Platform website address at{" "}
          <a href="https://www.alignerbase.com">www.alignerbase.com</a> (the
          &ldquo;AlignerBase Platform&rdquo;, &ldquo;Platform&rdquo;
          &ldquo;Website&rdquo;) owned and operated by AlignerBase LLC
          (&ldquo;we&rdquo;, &ldquo;us&rdquo;, &ldquo;our&rdquo;), collects,
          uses, maintains and discloses personally identifiable information
          collected from its users (&ldquo;visitor&rdquo;,
          &ldquo;subscriber&rdquo;, &rdquo;customer&rdquo;, &ldquo;you&rdquo;,
          &ldquo;your&rdquo;, &ldquo;their&rdquo; etc. each a
          &ldquo;user&rdquo;). We respect your privacy and we are committed to
          protect the personally identifiable information that you disclose to
          us.
        </p>
        <p>
          The purpose of this privacy policy is to assist you in understanding
          the purpose for which we collect, use, disclose and process the
          personal information you have provided to us or we collect from you.
          We are devoted to protecting your privacy. Please take a moment to
          check our privacy practices. By interacting or submitting any personal
          information or any other information to us for any purpose, it is
          deemed to be your full consent to permit us to collect, use and
          disclose such personal information and other information in accordance
          with this privacy policy.
        </p>
        <p>
          If you do not agree to this Privacy Policy, please do not use our
          platform or website. We reserve the right to make changes to this
          Privacy Policy. If we make a material change to this Privacy Policy,
          we will notify you by posting the changes in this Privacy Policy.
          Please check back from time to time to ensure that you are aware of
          these changes. Your continued use of the Service will signify your
          acceptance of these changes.
        </p>
        <h4>
          <strong>WHO WE ARE:</strong>
        </h4>
        <p>
          The AlignerBase Platform has been specially designed for Dentists,
          Orthodontists, Dental Practices, Dental Labs, Clear Aligner Companies
          etc. where we are offering Digital (CAD software based) design
          services. Currently we are offering design services for clear aligners
          but in future we may also provide design services including but not
          limited to crowns, abutments, surgical guides, dentures, partial
          frames, copings, frameworks, and other related dental prosthetic
          devices (the "Services") for dental appliances.
        </p>
        <h4>
          <strong>DATA ADMINISTRATOR:</strong>
        </h4>
        <p>
          Subject to this Privacy policy and in accordance with the data privacy
          law ALIGNERBASE LLC at 1007 N Orange St Ste 1357, 4<sup>th</sup>{" "}
          Floor, Wilmington, DE 19801 is the data controller, regarding the
          processing of personal data and on the free movement of such data. The
          data is stored in our database. Our server &amp; database is hosted on
          AWS currently set to their server in Ohio.
        </p>
        <h4>HOW AND WHAT TYPES OF INFORMATION WE MAY COLLECT FROM YOU</h4>
        <p>
          For the purpose of this privacy policy, Personally Identifiable
          Information (PII) means any information relating to an identified or
          identifiable individual which may include, but is not limited to your
          full name, email, phone number, address, city, state, religion, age,
          gender date of birth, language, mobile number, address, location
          ,country, zip code, social media profile link, business phone,
          business address, business email, business website (&ldquo;personal
          information&rdquo; or &ldquo;personal data&rdquo;). While using our
          website and its services, we may ask you to provide us with certain
          personal information or we may automatically obtain form you that can
          be used to contact or identify you. We may collect or obtain personal
          information relating to you from various sources described below.
        </p>
        <p>
          <strong>
            <em>User-Provided Information:</em>
          </strong>
        </p>
        <ul>
          <li>
            <strong>
              <u>Registration or Sign Up</u>
            </strong>
            . You may not require signing up or creating an account with us to
            visit our website and peruse its general contents. But to make any
            order or subscribe to our membership plan you need to sign up or
            register into our website through a sign-up form, which may require
            you to provide certain information such as your First Name, Last
            Name, Email Address, Password etc. Upon registration or sign up, we
            may send you an email or phone verification code to verify your
            account and access your member profile page. We hereby disclaim, and
            you hereby release us from any and all liability arising from any
            unauthorized registration and from any related claims, losses or
            damages alleged, arising from or relating to any unauthorized user
            registration and/or collection of personal information in connection
            with an unauthorized registration.
          </li>
          <li>
            <strong>
              <u>Plug-In:</u>
            </strong>
            We may have optional o-authorization registration option with
            &ldquo;plug-in&rdquo; tools or apps like Facebook, Google etc. Those
            tools may allow an automatic registration and a direct connection
            with those tools or apps servers through your browser or system and
            the registration information will be transferred to us by those
            tools or apps. If you register with us using the login credentials
            of such tools or apps, you are also subject to the terms and
            conditions &amp; privacy policy of such tools or applications. We
            use the &ldquo;OAuth" (open authorization) protocol to enable us to
            access this information without collecting your password when you
            agree to allow another tools or application to access your account
            information.
          </li>
          <li>
            <strong>
              <u>Profile or Account Information</u>
            </strong>
            <strong>:</strong>To use certain features within our website and its
            services, Categorizes and understanding your purpose of using our
            website and its services , we may also ask you to complete your
            profile with us by providing certain personal information under the
            personal info section of the Dashboard, which may include your full
            name, business phone, business address (including country, city,
            state, postcode, and street address), business email, business
            website, gender, date of birth, social media profiles/pages etc. If
            you have any question or concerns regarding this personal
            information collection process, please contact us at{" "}
            <a href="mailto:info@alignerbase.com">info@alignerbase.com</a>
          </li>
          <li>
            <strong>
              <u>Payment Information</u>
            </strong>
            <strong>:</strong>We ask for your credit card information at
            registration for verification purposes and later when you subscribe
            to our membership plan (the &ldquo;Tier&rdquo;). We do not locally
            process any payments &ndash; we do not collect or store any payment
            information and no payment information is transferred to us at any
            stage. All payments will be handled through the secure gateway
            system either through our website and or otherwise. We are using
            Stripe as our payment getaway and no payment data will be collected
            by the AlignerBase Platform itself. At the point of payments through
            the secure gateway system, you are transferred to the secure Stripe
            payment gateway which may collect including but not limited to your
            card details, billing address, contact, name, email, etc.) to
            complete the transaction and any of such data collected through
            Stripe will be subject to the conditions within their Privacy
            Statement.
          </li>
          <li>
            <strong>
              <u>Heath Information</u>
            </strong>
            : We may collect personal Protected Health Information (PHI) of
            client/patient of our user; whereas PHI is defined by the Health
            Insurance Portability and Accountability Act (HIPAA) of 1996 and the
            regulations promulgated thereunder. To comply with the HIPAA act we
            have a{" "}
            <a href="https://www.alignerbase.com/business-associate-agreement">
              Business Associate Agreement (BAA)
            </a>{" "}
            to protect any personal health information we may collect form the
            clients/patients from our users and we may use such data for the
            purpose mentioned in this privacy policy and it is your obligation
            to have consent of your client/patient for such a processing.
          </li>
          <li>
            Please note: Pursuant to BAA you are obliged to send PHI in
            anonymized form, because AlignerBase does not require any personal
            data of your patients. If you do not anonymize your patient personal
            data, AlignerBase receives first name and surname of your patients
            (and other personal data if any) and process it not intentionally.
            In such a case AlignerBase has the right to anonymize the patient
            data before using for our purposes and it is your obligation to have
            consent of your client/patient for such a processing.
          </li>
          <li>
            <strong>
              <u>Social Media</u>
            </strong>
            <strong>:</strong> We may collect your personal information if you
            interact with our social media pages (i.e., LinkedIn, Instagram,
            Facebook, etc.). We may collect embedded content from your social
            media profile and we may receive personal information about you from
            such social networking app subject to the privacy policy of those
            social networking sites.
          </li>
          <li>
            <strong>
              <u>Feedback or Review</u>
            </strong>
            <strong>:</strong> We also may collect personal information if
            included in any feedback or comment provided by our users. Feedback
            and similar communications from our users allow us to measure areas
            of interest, improve the efficiency of our website and its services,
            provide users with important information, and build features and
            functionality that will enhance your use of our website or apps and
            its services.
          </li>
          <li>
            <strong>
              <u>Communications with us via Email, Live Chat or Other ways</u>
            </strong>
            <strong>.</strong>When any user communicates with us using our
            contact us form, support email, phone call or live chat option
            or/with a phone call we may collect personal information through
            such communications.
          </li>
          <li>
            <strong>
              <u>Other Information</u>
            </strong>
            <strong>.</strong>You may otherwise choose to provide us information
            when you fill our Contact Us from, update or add information to your
            account with us or interact through your social media account.
          </li>
        </ul>
        <p>
          <strong>
            <em>Information Collected via Technology:</em>
          </strong>
        </p>
        <p>
          To make our website and its services more useful to you, our servers
          (which are hosted by a third-party service provider Amazon Web
          Services) may collect information from you, including your browser
          type, operating system, Internet Protocol (IP) address (a number that
          is automatically assigned to your computer when you use the internet,
          which may vary from session to session), domain name, and/or a
          date/time stamp for your visit. When you use our website and its
          services, we may collect information about the usage information,
          location information, log data, cookies and similar technologies
          "clear gifs" or "web beacons." This automatically collected
          information may include your IP address or other device address or ID,
          web browser and/or device type etc. We may use Google Analytics and/or
          other third-party vendors to collect information anonymously and
          report website trends without identifying individual visitors. Google
          use its cookies to track visitor interactions.
        </p>
        <h4>
          <strong>
            PURPOSE OF USE OR PROCESSING YOUR PERSONAL INFORMATION
          </strong>
        </h4>
        <p>
          If you visit and use our website or Platform and its services, we may
          collect, use and process your personal information in accordance with
          the terms set out in this privacy policy. Your personal information
          may be used or processed for the following purposes (hereinafter
          &ldquo;purposes&rdquo;):
        </p>
        <ul>
          <li>To personalize user experience;</li>
          <li>
            To maintain your account with us and to verify the accuracy of your
            name, address and other information;
          </li>
          <li>
            To send you texts, emails or other communication regarding general
            adherence information, service maintenance, updates, or changes to
            this Privacy Policy or any other relevant agreements;
          </li>
          <li>
            To respond to your questions and comments and provide customer
            support;
          </li>
          <li>
            to carry out marketing analysis and send you communications when we
            have your permission, or when permitted by law;
          </li>
          <li>
            To analyze and enhance our marketing communications and strategies
            (including identification of when emails we have sent to you have
            been received and read);
          </li>
          <li>
            To analyze trends and statistics regarding visitors' use of our
            Platform
          </li>
          <li>To improve our marketing and promotional efforts;</li>
          <li>
            To upload and distribute your cases correctly and provide you the
            high-quality services;
          </li>
          <li>
            To track certain information based upon your behavior on the
            Website. We use this information to do internal research on our
            users&rsquo; demographics, interests, and behavior to better
            understand our users;
          </li>
          <li>
            To protect the security and safety of our users and protect against
            and prevent fraud, unauthorized transactions, claims and other
            liabilities, and manage risk exposure, including by identifying
            potential hackers and other unauthorized users;
          </li>
          <li>
            To comply with applicable legal requirements, industry standards and
            our policies, including as is reasonably necessary to comply with
            legal process (including subpoenas, search warrants, court orders)
            and law enforcement instructions and orders;
          </li>
          <li>To enforce our Terms and Conditions.</li>
        </ul>
        <h4>DURATION OF HOLDING YOUR PERSONAL INFORMATION</h4>
        <p>
          Unless a longer retention period is required or permitted by law, we
          may hold your Personal information on our systems for as long as
          necessary to fulfill the purposes outlined in this Privacy Policy or
          until you request us to delete or erase it. Even if we delete your
          Personal information, it may persist on backup or archival media for
          legal, tax or regulatory purposes. We may also need to keep your
          personal information for accounting purposes. If we have asked for
          your permission to process your personal information and we have no
          other lawful grounds to continue with that processing, and you
          withdraw your permission, we will delete your personal information.
        </p>
        <h4>
          <strong>DISCLOSING OR SHARING YOUR PERSONAL INFORMATION:</strong>
        </h4>
        <p>
          We have sole discretion to take all reasonable steps to secure the
          information against unauthorized access or disclosure. Subject to this
          privacy policy and in accordance with any applicable law, we may
          disclose your personal information we collect from you, for the
          following circumstances and to the following third parties, whether
          they are located in your country or overseas.
        </p>
        <p>
          In the following circumstances and with your prior permission, we may
          share or disclose your information
        </p>
        <ul>
          <li>
            To any trusted third party, who assists us in operating our website,
            conducting our business, as long as those parties agree to keep this
            as confidential information
          </li>
          <li>
            To our employees, contractors and/or related entities in anonymized
            form and only that information necessary to design your treatment.
            But in no way they will have access to your names and personal
            information from our side. To whom we transfer or may transfer our
            rights and duties
          </li>
          <li>
            To any relevant government regulators or authority or law
            enforcement agency to comply with any laws or rules and regulations
            imposed by any governmental authority; and
          </li>
          <li>
            To any other party, entity, or authority when we have believed in
            good faith that, disclosing any information is necessary to protect
            our rights or interest or property.
          </li>
          <li>
            To any other party, entity or authority. when we have believed in
            good faith that, disclosing any information is appropriate in
            connection with efforts to investigate, prevent, or take other
            action regarding illegal activity, suspected fraud or other
            wrongdoing; to protect and defend our rights, property or safety of
            our company, users, employees, or others;
          </li>
          <li>
            To protect and defend the legal rights, liabilities, interests,
            property or safety of our company, users, employees, or others to
            protect and defend our rights,
          </li>
          <li>
            To whom you authorize us to disclose your personal information.
          </li>
          <li>
            To comply with applicable law or co-operate with law enforcement;
          </li>
        </ul>
        <p>
          We may also disclose or share your personal information in connection
          with a substantial corporate transaction, such as the sale of our
          business, a divestiture, merger, consolidation, or asset sale, or in
          the unlikely event of bankruptcy. In case of the change in ownership
          of our business through any viable means like a merger, an
          acquisition, takeover, or any similar event, your personal information
          may also be transferred to the new owner. In such scenarios, we will
          post a notice or otherwise notify you and collect your consent, as may
          be required by law, before the information is transferred and becomes
          subject to a different privacy policy.
        </p>
        <h4>
          <strong>ADVERTISING AND ANALYTICS:</strong>
        </h4>
        <p>
          To promote our Website and its services, we may use third-party
          advertising and analytics platforms, including but not limited to
          Google Ads, Facebook Ads, Google Analytics, Facebook's ad tracking
          feature, etc. We may also use remarketing or retargeting pixels
          through Google, Facebook and LinkedIn. We do not share personal
          information that directly identifies you with third-party advertisers
          for their direct marketing purposes.
        </p>
        <ol>
          <li>
            <strong>
              <u>Behavioral Remarketing:</u>
            </strong>{" "}
            We may use the Google, LinkedIn and Facebook remarketing service to
            advertise on third party websites to previous visitors to our site.
            It could mean that we advertise to previous visitors who haven't
            completed a task on our site, for example using the contact form to
            make an inquiry. This could be in the form of an advertisement on
            the Google search results page, or a site in the Google Display
            Network or somewhere on LinkedIn and Faceboo Third-party vendors,
            including Google, LinkedIn and Facebook, use cookies to serve ads
            based on someone's past visits to the AlignerBase Platform or
            Website. Any data collected will be used, share or processed in
            accordance with this privacy policy, as well as the Privacy Policies
            of Google, LinkedIn &amp; Facebook.
          </li>
          <li>
            <strong>
              <u>Google Ads and Analytics</u>
            </strong>
            <strong>: </strong>Google AdWords remarketing service is provided by
            Google LLC. You can Opt-Out of Google Analytics for Display
            Advertising and customize the Google Display Network ads by visiting
            the Google Ads Settings page you can find here:{" "}
            <a href="http://www.google.com/settings/ads/">
              http://www.google.com/settings/ads/
            </a>{" "}
            . Google also recommends installing the Google Analytics Opt-out
            Browser Add-on:{" "}
            <a href="https://tools.google.com/dlpage/gaoptout">
              https://tools.google.com/dlpage/gaoptout
            </a>{" "}
            for your web browser. Google Analytics Opt-out browser add-on
            provides website visitors the ability to prevent their information
            or data from being used by Google Analytics. For more information on
            the privacy practices of Google, please visit the Google Privacy
            &amp; Terms web page:&nbsp;
            <a href="http://www.google.com/intl/en/policies/privacy/">
              http://www.google.com/intl/en/policies/privacy/
            </a>
            .
          </li>
        </ol>
        <p>
          We may use Google Analytics. Google Analytics uses
          &ldquo;cookies&rdquo;, which are text files placed on your computer or
          mobile device, to help the website analyses how users use the site.
          The information generated by the cookie about your use of the website
          (including your IP address) will be transmitted to and stored by
          Google on servers in the United States. You can refuse the use of
          Google Analytics by clicking on{" "}
          <a href="https://tools.google.com/dlpage/gaoptout?hl=en">Opt-Out</a> (
          <a href="https://tools.google.com/dlpage/gaoptout">
            https://tools.google.com/dlpage/gaoptout
          </a>{" "}
          ) .
        </p>
        <ol start="164968693837950">
          <li>
            <strong>
              <u>Facebook Ads:</u>
            </strong>{" "}
            Facebook remarketing service is provided by Facebook Inc. You can
            learn more about interest-based advertising from Facebook by
            visiting this page:{" "}
            <a href="https://www.facebook.com/help/164968693837950">
              https://www.facebook.com/help/164968693837950
            </a>
            . To opt-out from Facebook&rsquo;s interest-based ads follow these
            instructions from Facebook:{" "}
            <a href="https://www.facebook.com/help/568137493302217">
              https://www.facebook.com/help/568137493302217
            </a>
            . Facebook adheres to the Self-Regulatory Principles for Online
            Behavioral Advertising established by the Digital Advertising
            Alliance. You can also opt-out from Facebook and other participating
            companies through the Digital Advertising Alliance&nbsp;or opt-out
            using your mobile device settings. For more information on the
            privacy practices of Facebook, please visit Facebook&rsquo;s Data
            Policy:&nbsp;
            <a href="https://www.facebook.com/privacy/explanation">
              https://www.facebook.com/privacy/explanation
            </a>
            <strong>. </strong>
          </li>
        </ol>
        <p>
          Also, we may use Facebook conversion tracking pixel tool which allows
          us to follow the actions of users after they are redirected to a
          provider's website by clicking on a Facebook advertisement. We are
          thus able to record the efficacy of Facebook advertisements for
          statistical and market research purposes. The collected data remain
          anonymous. Facebook can connect the data with your Facebook account
          and use the data for their advertising purposes, in accordance with
          the Facebook's privacy policy, you can find here:{" "}
          <a href="https://www.facebook.com/about/privacy/">
            https://www.facebook.com/about/privacy/
          </a>
          .
        </p>
        <p>
          Facebook Conversion Tracking also allows Facebook and its partners to
          show you advertisements on and outside Facebook. In addition, a cookie
          will be saved onto your computer for these purposes. By using our
          website you agree to the data processing associated with the
          integration of Facebook pixel. You can revoke your permission using{" "}
          <a href="https://www.facebook.com/ads/settings">
            https://www.facebook.com/ads/settings
          </a>
        </p>
        <h4>
          <strong>COOKIES POLICY: </strong>
        </h4>
        <p>
          Our website may use cookies to enhance user experience. Your web
          browser may place cookies on the hard drive of your device for
          record-keeping purposes and sometimes to track information/data about
          you. Cookies are small files and understood as IT data, in particular
          short text files, stored by a web browser or directly in the end
          devices of users, intended for the use of websites. Cookies usually
          contain the name of the website they come from, the storage time on
          the end device and a unique number.
        </p>
        <p>
          There have two basic types of cookies: Session Cookies and Persistent
          Cookies. Session files are temporary files that are stored on the
          user's end device until logging out, leaving the application or
          turning off the software (web browser). Permanent files are stored in
          the user's end device for the time specified in the parameters of
          cookies or until they are manually deleted by the user.
        </p>
        <p>
          By showing how and when visitors use the website, cookies help to
          identify how many unique users visit us, and tracking user trends
          and patterns. It also prevents you from having to re-enter your
          preferences on certain areas of the website where you may have entered
          preference information before. We may also use web beacons
          (single-pixel graphic files also known as &ldquo;transparent
          GIFs&rdquo;) to access cookies and to count users who visit the
          website or open HTML-formatted email messages. Cookies can compile
          information about your browsing habits and can also enhance your
          browsing experience. We may use cookies for the following purposes:
        </p>
        <ul>
          <li>To understand browsing habits on the website;</li>
          <li>
            To understand the number of visitors to our Website and its pages
            visited;
          </li>
          <li>To ensure that we give you the best user experience;</li>
          <li>To remember your preferences;</li>
          <li>
            To help us understand your preferences based on previous or current
            site activity;
          </li>
          <li>
            To help us compile aggregate data about site traffic and site
            interaction;
          </li>
          <li>To enable our site to function properly;</li>
        </ul>
        <p>
          Personal data (if any) that we collect from you through cookies may be
          passed to our third-party services providers for managing and/or
          administering our Website, or for data hosting/storage/backup. Your
          use of our Website constitutes consent by you to our use of cookies
          and the matters set out herein. You can instruct your browser, by
          editing its options, to stop accepting cookies or to prompt you before
          accepting a cookie from our website when you visit. Please note that,
          by not accepting cookies, you might not be able to use all functions
          of Website.
        </p>
        <p>
          If you turn cookies off, some of the features that make your website
          uses experience more efficient may not function properly. It won't
          affect the user's experience that makes your website experience more
          efficient and may not function properly.
        </p>
        <p>
          You may manage how your browser handles cookies and related
          technologies by adjusting its privacy and security settings. However,
          you can allow cookies from specific websites by making them trusted
          websites in your web browser. Find out how to manage cookies on
          popular browsers:
        </p>
        <p>
          <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&amp;hl=en">
            Google Chrome
          </a>
        </p>
        <p>
          <a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy">
            Microsoft Edge
          </a>
        </p>
        <p>
          <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">
            Mozilla Firefox
          </a>
        </p>
        <p>
          <a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">
            Microsoft Internet Explorer
          </a>
        </p>
        <p>
          <a href="https://www.opera.com/help/tutorials/security/privacy/">
            Opera
          </a>
        </p>
        <p>
          <a href="https://support.apple.com/en-gb/safari">Apple Safari</a>
        </p>
        <p>
          <em>
            We have sole rights to provide an explanation of these cookies
            policy. If you have any questioned regarding this cookies policy,
            please feel free to contact us at{" "}
          </em>
          <a href="mailto:info@alignerbase.com">info@alignerbase.com</a>
        </p>
        <h4>
          <strong>SERVER LOGS</strong>:
        </h4>
        <p>
          Information about some actions taken by users is logged in the server
          layer. These data are used only to administer the Application. The
          browsed resources are identified by URL addresses. In addition, the
          following may be saved:
        </p>
        <ul>
          <li>Time of arrival of the inquiry;</li>
          <li>Time of sending the response;</li>
          <li>
            Name of the user or client station - identification carried out by
            the HTTP protocol;
          </li>
          <li>
            Information about errors that occurred during the execution of the
            HTTP transaction;
          </li>
          <li>
            URL address of the page previously visited by the user or the
            Customer (referrer link) - in the case when the Application was
            accessed via a link;
          </li>
          <li>Information about the user's or customer's browser;</li>
          <li>Information about the user's or client's IP address;</li>
        </ul>
        <p>
          The above data is not associated with specific people browsing the
          website and is used only for the purpose of administering our Website
          or Platform.
        </p>
        <h4>
          <strong>EXTERNAL LINKS POLICY</strong>
        </h4>
        <p>
          Our website and its services may contain hypertext links to other
          websites or apps and provides access to products and services offered
          by third parties, we don&rsquo;t control their privacy policies. When
          you access another website or purchase any third-party products or
          services through such external website and use of any personal
          information you provide to them will be governed by the privacy policy
          of the operator of such website you are visiting or the provider of
          such products or services.
        </p>
        <h4>
          <strong>SOCIAL MEDIA PAGES/INTERACTIVE SERVICES </strong>
        </h4>
        <p>
          If you interact with us by and through the interactive services or any
          social media pages, you should be aware that the personal information
          that you submit by and through such venues can be read, collected
          and/or used by other users of these forums (depending on your privacy
          settings associated with the accounts you have with the hosts of such
          social media pages, if applicable), and could be used to send you
          unsolicited messages or otherwise to contact you without your consent
          or desire. We are not responsible for the personal information that
          you choose to submit in these forums. The Social Media Pages reside on
          websites that are operated independently from us, and we are not
          responsible for such social media sites, interfaces or privacy or
          security practices. We encourage you to review the privacy policies
          and settings of the social media sites with which you interact to help
          you understand those social media sites&rsquo; privacy practices. If
          you have questions about the security and privacy settings of any
          social media sites that you use, please refer to their applicable
          privacy statements or policies.
        </p>
        <h4>
          <strong>CHOICES OR RIGHTS YOU HAVE ABOUT YOUR INFORMATION:</strong>
        </h4>
        <p>
          You may, of course, decline to submit your personal information to us,
          in which case you may not be able to use our Platform or subscribe to
          our services. You may also control the types of notifications and
          communications we send, limit the personal information shared within
          the AlignerBase Platform about you, and otherwise amend certain
          privacy settings. We recognize the legal compliance to provide further
          privacy protections with respect to Personal Information we may
          collect from you. We undertake to respect the confidentiality of your
          personal information and to guarantee you can exercise your rights We
          will comply with different data practices as necessary. According to
          GDPR, CCPA, California Civil Code Section &sect; 1798.83 and other
          applicable USA and international data privacy law, you have the
          following rights
        </p>
        <ul>
          <li>
            <strong>Right to know:</strong> You have right to request to know
            what information we collect from you, how we have collected and used
            your Personal Information during the past 12 months.
          </li>
          <li>
            <strong>Right to Access:</strong> You have right to request copies
            of your personal information from us;
          </li>
          <li>
            <strong>Right to delete: </strong>You have the right to request us
            to delete your personal information that has been collected in the
            past 12 months.
          </li>
          <li>
            <strong>Right to Opt-Out</strong>: You have the right to opt-out of
            any use of your Personal Information as defined in the CCPA.
          </li>
          <li>
            <strong>Right to Nondiscrimination:</strong> You are entitled to
            exercise the rights described under this privacy policy free from
            any discrimination which are prohibited under CCPA.
          </li>
          <li>
            <strong>Right to correct:</strong> You have right to request us to
            have your personal information rectified if it is inaccurate or
            incomplete;
          </li>
          <li>
            <strong>Right to withdraw:</strong> You have the right to withdraw
            consent where you have previously given your consent to the
            processing of your personal information.
          </li>
          <li>
            <strong>Right to restrict our use of your information:</strong> You
            have right to restrict us from using your personal information or
            limit the way in which we can use it;
          </li>
          <li>
            <strong>Right to data portability:</strong> You have right to
            request us to move, copy or transfer your personal information.
          </li>
          <li>
            <strong>Right to object:</strong> You have right to object to our
            use of your personal information including where we use it for our
            legitimate interests or where we use your personal information to
            carry out profiling to inform our market research and user
            demographics.
          </li>
          <li>
            <strong>Lodge a complaint:</strong> You have right to bring a claim
            before the competent data protection authority.
          </li>
        </ul>
        <p>
          You may exercise your right to access, update, amend or delete your
          personal data by contacting us at{" "}
          <a href="mailto:info@alignerbase.com">info@alignerbase.com</a> . You
          may also write to us at the following address:
        </p>
        <div style={{ marginLeft: "1.5rem" }}>
          <div>Alignerbase LLC,</div>
          <div>1007 N ORANGE ST STE 1357</div>
          <div>
            4<sup>TH</sup> FLOOR
          </div>
          <div>WILMINGTON</div>
          <div>DE 19801</div>
        </div>
        <p>
          We may disclose and deliver your requested information free of charge
          within thirty days (30) days of receiving such your verifiable
          request. The time period to provide the required information may be
          extended once by an additional thirty days (30) days when reasonable
          necessary and with prior notice. Please note that we may ask you to
          verify your identity before responding to such requests. If you make a
          request, we will try our best to respond to you as soon as possible.
        </p>
        <h4>
          <strong>INTERNATIONAL DATA TRANSFER POLICY</strong>
        </h4>
        <p>
          Please be aware that AlignerBase Platform is registered and operated
          from USA, but we are offering our website and its services to
          international users as well. Personal information we collect from you
          may transfer to, stored and processed in any country where our website
          and its services are based or have facilities or where we engage with
          our service providers. We may transfer personal information that we
          maintain about you to recipients in countries other than the country
          in which the personal information was originally collected, including
          to the United States. Those countries may have data protection rules
          and law that are different from those of your country. However, while
          other countries may not have the same standards of data protection as
          those in your home country, we will continue to protect the Personal
          information that we transfer in line with this privacy policy. In
          certain circumstances, courts, law enforcement agencies, regulatory
          agencies or security authorities in those other countries may be
          entitled to access your Personal information.
        </p>
        <ul>
          <li>
            <strong>
              <u>Outside the United States</u>
            </strong>
            <strong>: </strong>By submitting any of your personal information to
            us, you are transferring your personal information to the USA and
            you consent to such transfer. We do not represent or warrant that
            our website or platform or any part thereof, is appropriate or
            available for use in any particular geographic location. If you
            choose to access our website or platform and use its services, you
            do so on your own initiative and at your own risk, and are
            responsible for complying with all local laws, rules, and
            regulations applicable in your jurisdiction. If you are using our
            website and its services outside from USA, please be aware that your
            information will be transferred to, stored and processed in the USA
            where our servers are located and our central database is operated.
            By using our website and its services, you consent to your
            information being transferred to our facilities and to the
            facilities of those third parties with whom we share it as described
            in this privacy policy.
          </li>
          <li>
            <strong>
              <u>Outside The EEA</u>
            </strong>
            <strong>:</strong> Please note that some of our service providers
            may be located outside the European Economic Area (the
            &ldquo;EEA&rdquo;). In such cases we will transfer your data only to
            such countries as approved by the European Commission as providing
            adequate level of protection of personal information, or enter into
            legal agreements ensuring an adequate level of data protection. We
            may transfer our databases containing your Personal information in
            connection with the transfer or sale of all (or substantially all)
            of our business assets, or in the event of a merger, consolidation
            or similar transaction.
          </li>
        </ul>
        <p>
          If you are located in the European Economic Area (EEA), we may process
          your personal information for the above purposes when
        </p>
        <ul>
          <ul>
            <li>
              You have clearly consented to the use of your personal
              information,
            </li>
            <li>
              We need your personal information to provide you with services or
              to respond to your inquiries,
            </li>
            <li>We have a legal obligation to use your personal information</li>
            <li>
              We have a legitimate interest in using your personal information
              to ensure and improve the safety, security, and performance of our
              service.
            </li>
          </ul>
        </ul>
        <p>
          We do not share any of your personally identifiable or transactional
          information with any person or entity, other than as set out in this
          policy. No other third party receives your personally identifiable
          information or other transactional data. However, please note that
          these rights are not absolute, and may be subject to our own
          legitimate interests and regulatory requirements.
        </p>
        <h4>
          <strong>CHILDREN PRIVACY POLICY </strong>
        </h4>
        <p>
          We take children's privacy seriously. Our website and its services
          comply with the GDPR and Children&rsquo;s Online Privacy Protection
          Act (COPPA) and the Children's online privacy protection law under the
          local territory of every user of our website and its services. We do
          not knowingly collect or solicit personal information from anyone
          under the age of 18. If any child under 18 wishes to use our website
          and its services, they can do so upon parental or guardian consent in
          accordance with applicable law. Where required by applicable law, we
          may ask children for consent from their parents or legal guardians
          before we approve any use of our website and its services. If any
          parent believes that their child is participating in any activity in
          our app, that collects personal information and the parent can contact
          us at info@alignerbase.com to request us to delete your child's
          account and any associated information. Upon such request we will
          delete all the collected information (if any) from our server.
        </p>
        <h4>GOVERNING LAW AND JURISDICTION</h4>
        <p>
          This Privacy Policy has been prepared based on provisions of the
          applicable states and federal law of the United States, California
          Consumer Privacy Act (CCPA), the General Data Protection Regulation
          (GDPR) of the European Union (EU), Children's Online Privacy
          Protection Act (COPPA), California Online Privacy Protection Act of
          2003 (CalOPPA), California Civil Code Section &sect; 1798.83
          applicable International data protection law and other relevant law of
          the local territory of the user. We and each
        </p>
        <h4>
          <strong>COMPLIANCE WITH LAWS AND REGULATION</strong>&nbsp;
        </h4>
        <p>
          We may disclose your personal information to comply with applicable
          law within or outside your country of residence, legal process,
          litigation requests or requests from public and governmental
          authorities; We may also disclose your personal information if it is
          necessary in order to investigate, prevent, or take action regarding
          illegal activities, suspected fraud, situations involving potential
          threats to the physical safety of any person, violations of our Terms
          of Service or as otherwise required by law; We encrypt the
          transmission of information on pages where you provide payment
          information. However, no security or encryption method can be
          guaranteed to protect information from hackers or human error. In no
          event, we do not sell trade, rent or otherwise transfer your
          personally identifiable information to any third parties except the
          above circumstances.
        </p>
        <h4>
          <strong>SECURITY OF YOUR PERSONAL INFORMATION</strong>
        </h4>
        <p>
          We are committed to protect and/or safeguard the data we collect from
          you. We take all reasonable and necessary steps to protect the
          information provided by you from unauthorized access. We may follow
          commonly accepted industry standards to protect the personal
          information submitted to us. We are using SSL CERTIFICATE, JWT
          Authentication System and other encryption-based security systems to
          protect the confidentiality of your personally identifiable
          information. However, no method of transmission over the Internet or
          method of electronic storages is 100% safe and secure. Therefore, we
          are not in the actual position to guarantee the absolute security of
          your information. If you have any questions about security on our
          website and its services, you can submit us an email we may take all
          reasonable steps and may allow third parties to assist us regarding
          processing your personal information and adopt proper security measure
          to safeguard your information for ensuring security.
        </p>
        <h4>
          <strong>AUTO RESPONDERS AND MARKETING OPT-OUTS</strong>
        </h4>
        <p>
          We may use auto responder tools to communicate with you by e-mail. You
          may opt-out of receiving emails and other messages from us by
          following the instructions in those messages. We may also use the
          information that you provide to us to communicate with you about our
          current activities or to notify you about any updates or changes to
          our services. If you decide that you would no longer want to receive
          such messages you can opt-out from receiving such emails from us. In
          order to opt-out you can click on an unsubscribe link provided at the
          bottom of every email or as described in that email. If you have
          difficulties opting out, you may contact us at info@alignerbase.com
        </p>
        <h4>
          <strong>ANTI-SPAM POLICY</strong>
        </h4>
        <p>
          We hate spam or junk e-mail (unsolicited commercial e-mail) the same
          as you do. We endorse and comply with the requirement of the
          Controlling the Assault of Non-Solicited Pornography and Marketing Act
          (CAN-SPAM Act of 2003) and other applicable unsolicited commercial
          e-mail laws. If you subscribe with us, you will always have an option
          to unsubscribe immediately.
        </p>
        <h4>
          <strong>YOUR CONSENT</strong>
        </h4>
        <p>
          By using our website and its services, you provide your consent to us
          to collect, process and transfer (including international transfer) of
          your personal information in accordance with this privacy policy.
          Wherever possible, we will attempt to obtain your explicit consent to
          collect and process your information. If you have given us explicit
          permission to do so, we may from time to time provide your name and
          contact information to selected partners whom we believe may provide
          services or Services you would find useful. Sometimes you may give
          implicit consent, such as when you send us a message through our
          support or by e-mail to which you would reasonably expect us to reply.
          Without your consent to use of your personal information for a
          specific purpose, we do not use your information in any way that would
          identify you personally. We may continue to use, disclose or process
          your information in accordance with this Privacy Policy and on the
          above basis until you withdraw your consent or it can be reasonably
          assumed that your consent no longer exists. You may withdraw your
          consent at any time by contacting us at info@alignerbase.com
        </p>
        <h4>DO NOT TRACK (DNT) PRIVACY PRACTICE:</h4>
        <p>
          Our website and its services do not respond to Do Not Track signals.
          We don't track you personally and we never sell your personal
          information. Here is what we track: we track how users use our site in
          general so that we can make it better. We are monitoring traffic,
          usage activity, site performance, and we use general analytic tools so
          that we can improve your experience. We do not associate any of this
          data with you personally. We never sell or share your personally
          identifiable information unless required to do so by law.
        </p>
        <h4>
          <strong>CHANGES OR MODIFICATION </strong>
        </h4>
        <p>
          We have sole discretion to modify or update or amend this privacy
          policy at any time without any prior notice. Changes or modification
          will take effect immediately upon their posting on our website. If we
          make any material changes to this policy, we will notify you here that
          it has been updated, so that you are aware of what information we
          collect, how we use it, and under what circumstances, if any, we use
          and/or disclose it. You acknowledge and agree that it is your
          responsibility to review this privacy policy periodically and become
          aware of modifications.
        </p>
        <h4>
          <strong>YOUR ACCEPTANCE TO THESE POLICIES</strong>
        </h4>
        <p>
          By using our website and its services, you imply your acceptance of
          this privacy policy. If you do not agree to this privacy policy,
          please do not use our website and its services. Your continued use of
          our website and its services will be deemed your acceptance of those
          changes.
        </p>
        <h4>
          <strong>CONTACT US</strong>
        </h4>
        <p>
          We have sole jurisdiction to provide an explanation of these Privacy
          Policy. If you have any question about this Privacy Policy or would
          like to access or modify your personal identifiable information,
          please contact us.
        </p>
        <div>
          <strong>Address:</strong>&nbsp;Alignerbase LLC
        </div>
        <div style={{ marginLeft: "4.5rem" }}>
          <div>1007 N ORANGE ST STE 1357</div>
          <div>
            4<sup>TH</sup> FLOOR
          </div>
          <div>WILMINGTON</div>
          <div>DE 19801</div>
        </div>
        <div>
          <strong>Email:</strong> info@alignerbase.com
        </div>
        <div>
          <strong>Phone:</strong> +1-917-580-7864
        </div>
        <p>
          <strong>FYI:</strong>&nbsp;<strong>Please note that</strong>
        </p>
        <ul>
          <li>This privacy policy was last updated on January, 2023.</li>
          <li>
            We reserve the right to change this privacy policy at any time.
            Modifications to this privacy policy can be made without prior
            notification.
          </li>
          <li>
            Continued use of our platform signifies your acceptance of any
            changes to this privacy policy.
          </li>
        </ul>
      </div>
    </Layout>
  )
}

export default PrivacyPolicy
